import React, {Component} from "react";
import * as Message from '../shared/Message';
import * as _integracaoMatriculaService from "../../service/integracaoMatricula-service";
import {CardActions, Container, Grid, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export class ReplayIntegracaoUpSellingTotal extends Component {
  static displayName = ReplayIntegracaoUpSellingTotal.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      matriculaCrm: "",
      diaVencimento: "",
      result: {},
      selectedFile: null,
    };
  }

  change(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({result: {}})

    this.setState({isLoading: true});

    const result = await _integracaoMatriculaService.ReplayTotalUpSelling(
      this.state.matriculaCrm,
      this.state.diaVencimento
    );

    this.setState({isLoading: false, result});

    if (this.state.result != null) {
      Message.ExibeListMensagem(this.state.result.messages);
    } else {
      Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
    }
  };

  render() {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          m: 2,
        }}
      >
        <Card>
          <CardHeader
            title="Replay Integração Total de UpSelling"
          />

          <Container>
            <form onSubmit={this.handleSubmit}>
              <CardContent>
                <Stack
                  spacing={2}
                  direction="column"
                  sx={{width: '100%'}}
                >
                  <TextField
                    required
                    label="Código Matrícula UpSelling"
                    type="text"
                    name="matriculaCrm"
                    disabled={this.state.isLoading}
                    onChange={event => this.change(event)}
                    value={this.state.matriculaCrm}
                  />

                  <TextField
                    required
                    label="Dia de Vencimento"
                    type="number"
                    min="1"
                    max="31"
                    name="diaVencimento"
                    disabled={this.state.isLoading}
                    onChange={event => this.change(event)}
                    value={this.state.diaVencimento}
                  />
                </Stack>
              </CardContent>

              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={this.state.isLoading}
                  sx={{
                    maxWidth: 400,
                    margin: 'auto',
                    mb: 2,
                  }}
                >
                  {this.state.isLoading ? <CircularProgress size="2rem"/> : 'Enviar'}
                </Button>
              </CardActions>
            </form>
          </Container>
        </Card>
      </Box>
    );
  }
}

const ReplayIntegracaoTotalUpSelling = () => {
    return <ReplayIntegracaoUpSellingTotal />;
};

export default ReplayIntegracaoTotalUpSelling;
