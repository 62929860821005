import {atom} from "recoil";
import {Section} from "../../hooks/use-can-view-section";

const sectionsState = atom<Array<Section>>({
  key: 'sectionsState',
  default: [],
  effects: [
  ]
});

export default sectionsState
