import { useEffect, useMemo, useState } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import * as _responsavelService from "../../service/responsavel-service";
import * as _taxService from "../../service/income-tax-service";
import * as _matriculaService from "../../service/matricula-service";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';
import { Receipt, RequestQuote, StickyNote2 } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Tooltip,
    Typography,

} from "@mui/material";
import { CustomMuiTable } from 'src/components/CurstomMuiTable';
import { GridActionsCellItem, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import InfoComponent from 'src/components/InfoComponent';

interface StudentIncome {
    clientCode: string;
    studentName: string;
    studentCode: string;
    matriculaERPId: string;
    year: string;
}

const DeclaracaoRecebimentos = (props: any) => {
    const { Matricula, Unidade } = props;
    const [errorTitle, setErrorTitle] = useState('');
    const [errorText, setErrorText] = useState('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(3);
    const [declaracoes, setDeclaracoes] = useState<StudentIncome[]>(null);
    const [declaracaoMatricula, setDeclaracaoMatricula] = useState<StudentIncome>(null);
    const [isloading, setIsLoading] = useState(true);
    const [isLoadingDocumentGeneration, setIsLoadingDocumentGeneration] = useState(false);
    const [erroRequisicao, setErroRequisicao] = useState(false);

    useEffect(() => {
        setErroRequisicao(false);
        setIsLoading(true);
        setDeclaracaoMatricula(null);
        setDeclaracoes(null);
        GetDeclaracoesEResponsavel();
    }, [Matricula])


    const GetDeclaracoesEResponsavel = async () => {
        try {
            const responsavel = await _responsavelService.GetResponsavelByMatricula(Matricula.idMatricula);
            if (!responsavel) {
                setErroRequisicao(true);
                setErrorTitle(null);
                setErrorText('O responsável da matrícula não foi encontrado!');
                setIsLoading(false);
                return
            }
            if (!responsavel.cpf) {
                setErroRequisicao(true);
                setErrorTitle(null);
                setErrorText('O CPF do responsável da matrícula não foi encontrado!');
                setIsLoading(false);
                return
            }
            const { response: declaracoes = null } = await _taxService.GetAllStudentWithIncomeTax(responsavel.cpf);
            if (!declaracoes || !declaracoes.length) {
                setErroRequisicao(true);
                setErrorTitle('Nenhuma declaração Encontrada!');
                setErrorText(`Não foram encontradas declarações para o responsável ${responsavel.nome}, (isso inclui a declaração da matrícula atual e todas as outras matrículas vinculadas ao responsável)`);
                setIsLoading(false);
                setDeclaracoes(null);
                return
            }
            if (declaracoes.find(m => m.matriculaERPId === Matricula.codigoMatriculaErp))
                setDeclaracaoMatricula(declaracoes.find(m => m.matriculaERPId === Matricula.codigoMatriculaErp));
            else
                setDeclaracaoMatricula(null);
            setDeclaracoes(declaracoes);
        } catch {
            setErroRequisicao(true);
            setErrorTitle('Erro ao buscar declarações!');
            setErrorText('Ocorreu um erro ao buscar as declarações do responsável, por favor tente novamente mais tarde.');
        } finally {
            setIsLoading(false);
        }
    }

    const generateDocument = async (row: StudentIncome, type: number) => {
        setIsLoadingDocumentGeneration(true);
        try {
            const incomeRequest = {
                clientCode: row.clientCode,
                studentCode: row.studentCode,
                year: row.year,
                reportType: type
            }
            const { response: url = null } = await _taxService.GenerateIncomeTaxDocument(incomeRequest);
            if (url)
                window.open(url, '_blank');
        } finally {
            setIsLoadingDocumentGeneration(false);
        }
        return
    }

    const columns: GridColDef[] = [
        {
            field: 'studentName',
            headerName: 'Nome do Aluno',
            width: 240
        },
        {
            field: 'studentCode',
            headerName: 'Codigo da Matrícula',
            width: 150
        },
        {
            field: 'matriculaERPId',
            headerName: 'Matrícula ERP',
            width: 130
        },
        {
            field: 'year',
            headerName: 'Ano',
            width: 100,
            valueGetter: (params: GridValueGetterParams<StudentIncome>) => `${new Date(params.row.year).getFullYear()}`
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Gerar Documento',
            flex: 1,
            minWidth: 140,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"Clique aqui para realizar gerar o documento referente ao Recebimento Total"}>
                            <IconButton
                                aria-label='download recebimento total'
                            >
                                <Receipt />
                            </IconButton>
                        </Tooltip>}
                    label="Recebimento Total"
                    onClick={() => generateDocument(params.row, 0)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={
                        <Tooltip title={"Clique aqui para realizar gerar o documento referente ao Imposto de Renda"}>
                            <IconButton
                                aria-label='download imposto de renda'
                            >
                                <RequestQuote />
                            </IconButton>
                        </Tooltip>}
                    label="Imposto de Renda"
                    onClick={() => generateDocument(params.row, 1)}
                    showInMenu
                />
            ]
        },
    ];


    const paginatedRows = useMemo(() => {
        return declaracoes && declaracoes.length ? declaracoes
        .filter(m => m.matriculaERPId !== Matricula.codigoMatriculaErp)
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, index) => ({
            ...row,
            id: index.toString()
        })) : [];
    }, [declaracoes, page, rowsPerPage]);
    if (erroRequisicao) {
        return <ErrorMessage message={errorText} title={errorTitle} />
    }
    if (isloading) {
        return (<LoadingAnimation> </LoadingAnimation>)
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
            }}
        >
            <Grid
                container
                justifyContent="center"
                alignItems='center'
                spacing={3}
                sx={{
                    py: 4,
                    mb: 3
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Stack
                        spacing={1}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        sx={{
                            mb: 4
                        }}
                    >
                        <Typography variant="h5">
                            Declarações de Recebimentos
                        </Typography>
                        <StickyNote2
                            sx={{
                                fontSize: '1.8rem',
                            }}
                        />
                    </Stack>
                </Grid>
                {declaracaoMatricula ?
                    <Grid
                        item
                        xs={12}
                    >
                        <Card
                            sx={{
                                mb: 3
                            }}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar aria-label='Inicial Nome Estudante'>
                                        {declaracaoMatricula.studentName.charAt(0).toUpperCase()}
                                    </Avatar>
                                }
                                title="Declarações de Recebimentos da Matrícula Selecionada"
                            />
                            <CardContent>
                                <Typography variant="body1">
                                    <strong>Aluno:</strong> {declaracaoMatricula.studentName}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Código do Cliente:</strong> {declaracaoMatricula.clientCode}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Código do Aluno:</strong> {declaracaoMatricula.studentCode}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Matrícula ERP:</strong> {declaracaoMatricula.matriculaERPId}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Ano:</strong> {new Date(declaracaoMatricula.year).getFullYear()}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {isLoadingDocumentGeneration ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled
                                            endIcon={<CircularProgress/>}
                                        >
                                            Gerando Documento...
                                        </Button>
                                    :
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isLoadingDocumentGeneration}
                                            onClick={() => generateDocument(declaracaoMatricula, 0)}
                                        >
                                            Recebimento Total
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            disabled={isLoadingDocumentGeneration}
                                            onClick={() => generateDocument(declaracaoMatricula, 1)}
                                        >
                                            Imposto de Renda
                                        </Button>
                                    </>
                                }
                            </CardActions>
                        </Card>
                    </Grid>
                    :
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            direction={{ md: 'row', sm: 'column' }}
                        >
                            <InfoComponent
                                title='Não foi encontrada as declarações para essa matrícula!'
                                desc={`Infelizmente não foram encontradas as declarações para essa matrícula.${paginatedRows && paginatedRows.length && " Mas é possível visualizar logo abaixo as outras matrículas vinculadas ao responsável que possuem declarações."}`}
                                type={'info'}
                            />
                        </Stack>
                    </Grid>
                }
                {!!paginatedRows.length &&
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{
                                mb: 4
                            }}
                        >
                            <Typography variant="h6">
                                Outras matrículas vinculadas ao responsável que possuem declarações.
                            </Typography>
                        </Stack>
                    </Grid>
                }
                {!!paginatedRows.length &&
                    <Grid
                        item
                        xs={12}
                    >
                        <CustomMuiTable
                            columns={columns}
                            count={paginatedRows?.length as number | null | undefined}
                            loading={isloading || isLoadingDocumentGeneration}
                            items={paginatedRows}
                            checkboxSelection={false}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            rowsPerPageOptions={[rowsPerPage]}
                            onRowsPerPageChange={() => setRowsPerPage(rowsPerPage)}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

export default withAppContext(withUnidadeMatricula(DeclaracaoRecebimentos, 'Declaracao de Recebimentos'));