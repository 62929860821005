import {selector} from "recoil";
import {selectedMatriculaState} from "../atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../atoms/selectedUnidadeState";

const selectedUnidadePossuiFacialSelector = selector({
  key: 'selectedUnidadePossuiFacialSelector',
  get: ({get}) => {
    const unidade = get(selectedUnidadeState);
    return unidade?.possuiFacial ?? false
  },
});

export default selectedUnidadePossuiFacialSelector;
