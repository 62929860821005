import Home from "./pages/Home/Home";
import Matricula from "./pages/Matricula/Matricula";
import Responsaveis from "./pages/Responsaveis/Responsaveis";
import Financeiro from "./pages/Financeiro/Financeiro";
import TrocaDeTurma from "./pages/TrocaDeTurma/TrocaDeTurma";
import TrocaDeCurso from "./pages/TrocaCurso/TrocaCurso";
import TrocaResponsavelFinanceiro from "./pages/TrocaResponsavelFinanceiro/TrocaResponsavelFinanceiro";
import PlanoDePagamento from "./pages/PlanoDePagamento/PlanoDePagamento";
import Cancelamento from "./pages/Cancelamento/Cancelamento";
import Bolsas from "./pages/Bolsas/Bolsas";
import TrocaMetodoPagamento from "./pages/TrocaMetodoPagamento/TrocaMetodoPagamento";
import TrocaResponsavelPedagogico from "./pages/TrocaResponsavelPedagogico/TrocaResponsavelPedagogico";
import AtualizarContratoMovimentacoes from "./pages/AtualizarContratoMovimentacoes/AtualizarContratoPage";
import ReplayIntegracaoTotal from "./components/Integracao/ReplayIntegracaoTotal";
import ReplayIntegracaoTotalUpSelling from "./components/IntegracaoUpSelling/ReplayIntegracaoUpSellingTotal";
import AtualizarContratoIntegracoes from "./pages/AtualizarContratoIntegracoes/AtualizarContratoPage";
import {ReplayIntegracaoParcial} from "./components/Integracao/integracaoParcial/ReplayIntegracaoParcial";
import ReplayIntegracaoParcialUpSelling from "./components/IntegracaoUpSelling/ReplayIntegracaoUpSellingParcial";
import ReplayIntegracaoParcialRegular from "./components/Integracao/ReplayIntegracaoRegularParcial";
import ReplayIntegracaoParcialReserva from "./components/Integracao/ReplayIntegracaoReservaParcial";
import ReplayReservaTotal from "./components/Integracao/ReplayReservaTotal";
import ReplayParcialMaterial from "./pages/ReplayParcialMaterial/ReplayParcialMaterial";
import BlockedResources from "./pages/BlockedResources/BlockedResources";
import ManutencaoFinanceiraFrank from "./pages/ManutencaoFinanceiraFrank/ManutencaoFinanceiraFrank";
import ManutencaoFinanceira from "./pages/ManutencaoFinanceira/ManutencaoFinanceira";
import PainelConciliacao from "./components/ConciliacaoMatricula/PainelConciliacaoMatricula";
import DeclaracaoDeBolsa from "./pages/DeclaracaoBolsa/DeclaracaoDeBolsa";
import DeclaracaoDeRacaCor from "./pages/DeclaracaoRacaCor/DeclaracaoDeRacaCor";
import CarteirnhaEscolar from "./pages/CarteirinhaEscolar/CarteirinhaEscolar";
import ReconhecimentoFacial from "./pages/ReconhecimentoFacial/ReconhecimentoFacial";
import Colaboradores from "./pages/Colaboradores/Colaboradores";
import Publicidade from "./pages/Publicidade/Publicidade";
import PermissaoHorario from "./pages/PermissaoHorario/PermissaoHorario";
import SvgIcon from "@mui/material/SvgIcon";
import {AccountBoxTwoTone, DashboardTwoTone} from "@mui/icons-material";
import Icon from "@mdi/react";
import {mdiBadgeAccount, mdiCog, mdiFileCompare, mdiFilePdfBox, mdiListStatus} from "@mdi/js";
import React from "react";
import {Section} from "./hooks/use-can-view-section";
import TrocaMetodoPagamentoV2 from "./pages/TrocaMetodoPagamento/TrocaMetodoPagamento-v2";
import DeclaracaoRecebimentos from "./pages/DeclaracaoRecebimentos";
import Visitantes from "./pages/Visitantes/Visitantes";

const routes: Section[] = [
  {
    subheader: "Secretaria Web",
    items: [
      {
        title: "Início",
        path: "/home",
        icon: (
          <SvgIcon fontSize="small">
            <DashboardTwoTone/>
          </SvgIcon>
        ),
        exact: true,
        component: Home
      },
    ]
  },
  {
    items: [
      {
        title: "Matrícula",
        path: `matricula`,
        icon: (
          <SvgIcon fontSize="small">
            <AccountBoxTwoTone/>
          </SvgIcon>
        ),
        items: [
          {
            title: "Detalhes",
            path: `/matricula/detalhes`,
            exact: true,
            component: Matricula
          },
          {
            title: "Responsáveis",
            path: `/matricula/responsaveis`,
            exact: true,
            component: Responsaveis
          },
          {
            title: "Financeiro",
            path: `/matricula/financeiro`,
            exact: true,
            component: Financeiro
          },
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Movimentações",
        path: `/movimentacoes`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiListStatus} size={1}/>
          </SvgIcon>
        ),
        items: [
          {
            title: "Atualização de Contrato",
            path: `/movimentacoes/atualizacao-de-contrato`,
            exact: true,
            component: AtualizarContratoMovimentacoes
          },
          {
            title: "Troca de Turma",
            path: `/movimentacoes/troca-de-turma`,
            exact: true,
            component: TrocaDeTurma
          },
          {
            title: "Troca de Curso",
            path: `/movimentacoes/troca-de-curso`,
            exact: true,
            component: TrocaDeCurso
          },
          {
            title: "Responsável Financeiro",
            path: `/movimentacoes/responsavel-financeiro`,
            exact: true,
            component: TrocaResponsavelFinanceiro
          },
          {
            title: "Plano de Pagamento",
            path: `/movimentacoes/plano-de-pagamento`,
            exact: true,
            component: PlanoDePagamento
          },
          {
            title: "Cancelamento",
            path: `/movimentacoes/cancelamento`,
            exact: true,
            component: Cancelamento
          },
          {
            title: "Bolsas",
            path: `/movimentacoes/bolsas`,
            exact: true,
            component: Bolsas
          },
          {
            title: "Troca Método de Pagamento",
            path: `/movimentacoes/troca-metodo-pagamento`,
            exact: true,
            component: TrocaMetodoPagamento
          },
          {
            title: "Troca Método de Pagamento",
            path: `/movimentacoes/troca-metodo-pagamento-v2`,
            exact: true,
            component: TrocaMetodoPagamentoV2
          },
          {
            title: "Troca Resp. Pedagógico",
            path: `/movimentacoes/troca-responsavel-pedagogico`,
            exact: true,
            component: TrocaResponsavelPedagogico
          }
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Conciliação de Matrícula",
        path: `/conciliacao`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiFileCompare} size={1} />
          </SvgIcon>
        ),
        exact: true,
        component: PainelConciliacao
      },
    ]
  },
  {
    items: [
      {
        title: "Declarações",
        path: `/declaracoes`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiFilePdfBox} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Bolsa",
            path: `/declaracoes/bolsa`,
            exact: true,
            component: DeclaracaoDeBolsa
          },
          {
            title: "Raça e Cor",
            path: `/declaracoes/raca-cor`,
            exact: true,
            component: DeclaracaoDeRacaCor
          },
          {
            title: "Carteirinha Escolar",
            path: `/declaracoes/carteirinha-escolar`,
            exact: true,
            component: CarteirnhaEscolar
          },
          {
            
            title: "Declarações de Recebimentos",
            path: `/declaracoes/recebimentos`,
            exact: true,
            component: DeclaracaoRecebimentos
          }
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Reconhecimento Facial",
        path: `/reconhecimento-facial`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiBadgeAccount} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Alunos",
            path: `/reconhecimento-facial/alunos`,
            exact: true,
            component: ReconhecimentoFacial
          },
          {
            title: "Responsáveis",
            path: `/reconhecimento-facial/responsaveis`,
            exact: true,
            component: ReconhecimentoFacial
          },
          {
            title: "Colaboradores",
            path: `/reconhecimento-facial/colaboradores`,
            exact: true,
            component: Colaboradores
          },
          {
            title: "Publicidade",
            path: `/reconhecimento-facial/publicidade`,
            exact: true,
            component: Publicidade
          },
          {
            title: "Permissão de Horários",
            path: `/reconhecimento-facial/permissao-horarios`,
            exact: true,
            component: PermissaoHorario
          },
          {
            title: "Visitantes",
            path: `/reconhecimento-facial/visitantes`,
            exact: true,
            component: Visitantes
          }
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Integração",
        path: `/integracao`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiCog} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Replay Total",
            path: `/integracao/replay-total`,
            exact: true,
            component: ReplayIntegracaoTotal
          },
          {
            title: "Contrato",
            path: `/integracao/contrato`,
            exact: true,
            component: AtualizarContratoIntegracoes
          },
          {
            title: "Replay Parcial",
            path: `/integracao/replay-parcial`,
            exact: true,
            component: ReplayIntegracaoParcial
          },
          {
            title: "Replay Total Upselling",
            path: `/integracao/replay-total-upselling`,
            exact: true,
            component: ReplayIntegracaoTotalUpSelling
          },
          {
            title: "Replay Parcial Upselling",
            path: `/integracao/replay-parcial-upselling`,
            exact: true,
            component: ReplayIntegracaoParcialUpSelling
          },
          {
            title: "Replay Parcial Regular",
            path: `/integracao/replay-parcial-regular`,
            exact: true,
            component: ReplayIntegracaoParcialRegular
          },
          {
            title: "Replay Parcial Material",
            path: `/integracao/replay-parcial-material`,
            exact: true,
            component: ReplayParcialMaterial
          },
          {
            title: "Bloqueio de Recursos",
            path: `/integracao/bloqueio-de-recursos`,
            exact: true,
            component: BlockedResources
          },
          {
            title: "Manutenção Financeira",
            path: `/integracao/manutencao-financeira`,
            exact: true,
            component: ManutencaoFinanceira
          },
          {
            title: "Manutenção Financeira - Frank",
            path: `/integracao/manutencao-financeira-frank`,
            exact: true,
            component: ManutencaoFinanceiraFrank
          },
          {
            title: "Replay Parcial Reserva",
            path: `/integracao/replay-parcial-reserva`,
            exact: true,
            component: ReplayIntegracaoParcialReserva
          },
          {
            title: "Replay Total Reserva",
            path: `/integracao/replay-total-reserva`,
            exact: true,
            component: ReplayReservaTotal
          },
        ]
      },
    ]
  }
];

export default routes;
