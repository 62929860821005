import { gql } from "@apollo/client";
import { dispatchGraphError } from "../utils/dispatchGraphError";
import { ResponsavelResponse } from "seb-graph-api-types/generated";

export async function AtualizarResponsavelMovimentacao(idMatricula, responsavel) {
    delete responsavel.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            atualizarResponsavelMovimentacao
        },
        errors
    } = await client.mutate({
        mutation: gql`
        mutation AtualizarResponsavelMovimentacao($idMatricula: String, $responsavel: ResponsavelRequest) {
          atualizarResponsavelMovimentacao(idMatricula: $idMatricula, responsavel: $responsavel) {
            id
            nome
            cpf
            grauParentesco
            grauParentescoNome
            celular
            telefoneFixo
            email
            cep
            cidade
            bairro
            endereco
            numero
            complemento
            estado
            emancipado
            novaSenha
            senhaSite
            senha
            dataNascimento
            cadastroPendente
            urlImagemPerfil
            rg
            rateio
            codigoErp
            codigoCliente
            urlContrato
            responsavelPF
            tipoResponsavel
          }
        }
        `,
        variables: {
            idMatricula,
            responsavel
        }
    })

    dispatchGraphError(errors);

    return atualizarResponsavelMovimentacao;
}

export async function AtualizarResponsavelPedagogicoMovimentacao(idMatricula, responsavel) {
    delete responsavel.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            alteraResponsavelPedagogico
        },
        errors
    } = await client.mutate({
        mutation: gql`
        mutation AlteraResponsavelPedagogico($idMatricula: String, $responsavel: ResponsavelRequest) {
          alteraResponsavelPedagogico(idMatricula: $idMatricula, responsavel: $responsavel) {
            value {
              id
              nome
              cpf
              grauParentesco
              grauParentescoNome
              celular
              telefoneFixo
              email
              cep
              cidade
              bairro
              endereco
              numero
              complemento
              estado
              emancipado
              novaSenha
              senhaSite
              senha
              dataNascimento
              cadastroPendente
              urlImagemPerfil
              rg
              rateio
              codigoErp
              codigoCliente
              urlContrato
              responsavelPF
              tipoResponsavel
            }
            messages {
              text
              typeMessage
            }
            statusCode
          }
        }
        `,
        variables: {
            idMatricula,
            responsavel
        }
    })

    dispatchGraphError(errors);

    return alteraResponsavelPedagogico;
}

export async function GetResponsavelByMatricula(idMatricula): Promise<ResponsavelResponse> {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            getResponsavelByMatricula
        },
        errors
    } = await client.query({
        query: gql`
        query GetResponsavelByMatricula($idMatricula: String) {
          getResponsavelByMatricula(idMatricula: $idMatricula) {
            id
            nome
            cpf
            grauParentesco
            grauParentescoNome
            celular
            telefoneFixo
            email
            cep
            cidade
            bairro
            endereco
            numero
            complemento
            estado
            emancipado
            novaSenha
            senhaSite
            senha
            dataNascimento
            cadastroPendente
            urlImagemPerfil
            codigoErp
            codigoCliente
            responsavelPF
            tipoResponsavel
            rateio
            rg
            urlContrato
          }
        }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return getResponsavelByMatricula;
}


export async function GetResponsavelPedagogicoByMatricula(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            getResponsavelPedagogicoByMatricula
        },
        errors
    } = await client.query({
        query: gql`
        query GetResponsavelPedagogicoByMatricula($idMatricula: String) {
            getResponsavelPedagogicoByMatricula(idMatricula: $idMatricula) {
                id
                nome
                cpf
                grauParentesco
                grauParentescoNome
                celular
                telefoneFixo
                email
                cep
                cidade
                bairro
                endereco
                numero
                complemento
                estado
                emancipado
                novaSenha
                senhaSite
                senha
                dataNascimento
                cadastroPendente
                urlImagemPerfil
                rg
                rateio
                codigoErp
                codigoCliente
                urlContrato
                responsavelPF
                tipoResponsavel
            }
        }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return getResponsavelPedagogicoByMatricula;
}

export async function GetbyCpfResponsavel(cpf) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            getResponsavelByCPF
        },
        errors
    } = await client.query({
        query: gql`
        query GetResponsavelByCPF($cpf: String) {
          getResponsavelByCPF(cpf: $cpf) {
            id
            nome
            cpf
            grauParentesco
            grauParentescoNome
            celular
            telefoneFixo
            email
            cep
            cidade
            bairro
            endereco
            numero
            complemento
            estado
            emancipado
            novaSenha
            senhaSite
            senha
            dataNascimento
            cadastroPendente
            urlImagemPerfil
            codigoErp
            codigoCliente
            responsavelPF
            tipoResponsavel
            rateio
            rg
            urlContrato
          }
        }
        `,
        variables: {
            cpf
        }
    })

    dispatchGraphError(errors);

    return { ...getResponsavelByCPF };
}
export async function ListResponsaveisByMatricula(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;

    return client.query({
        query: gql`
          query ListResponsaveisByMatricula($idMatricula: String) {
              listMatriculaGeralResponsaveisByMatricula(idMatricula: $idMatricula) {
                  id
                  nome
                  cpf
                  grauParentesco
                  grauParentescoNome
                  celular
                  telefoneFixo
                  email
                  cep
                  cidade
                  bairro
                  endereco
                  numero
                  complemento
                  estado
                  emancipado
                  novaSenha
                  senhaSite
                  senha
                  dataNascimento
                  cadastroPendente
                  urlImagemPerfil
                  rg
                  rateio
                  codigoErp
                  codigoCliente
                  urlContrato
                  responsavelPF
                  tipoResponsavel
              }
          }
      `,
        variables: {
            idMatricula
        },
    }).then(data => {
        const {
            data: {
                listMatriculaGeralResponsaveisByMatricula
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return listMatriculaGeralResponsaveisByMatricula;
    }).catch(console.error);
}
export async function ListResponsaveisMatriculaByCodigoErp(idMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            listMovimentacoesResposaveisByMatricula
        },
        errors
    } = await client.query({
        query: gql`
            query ListMovimentacoesResposaveisByMatricula($idMatricula: String!) {
                listMovimentacoesResposaveisByMatricula(idMatricula: $idMatricula) {
                    id
                    nome
                    cpf
                    grauParentesco
                    grauParentescoNome
                    celular
                    telefoneFixo
                    email
                    cep
                    cidade
                    bairro
                    endereco
                    numero
                    complemento
                    estado
                    emancipado
                    novaSenha
                    senhaSite
                    senha
                    dataNascimento
                    cadastroPendente
                    urlImagemPerfil
                    rg
                    rateio
                    codigoErp
                    codigoCliente
                    urlContrato
                    responsavelPF
                    tipoResponsavel
                }
            }
        `,
        variables: {
            idMatricula
        }
    })

    dispatchGraphError(errors);

    return listMovimentacoesResposaveisByMatricula;
}
export async function InserirResponsavelMovimentacao(idMatricula, responsavel) {
    delete responsavel.__typename;
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            criarMovimentacoesResponsavelByMatricula
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation CriarMovimentacoesResponsavelByMatricula($idMatricula: String!, $responsavel: ResponsavelRequest) {
                criarMovimentacoesResponsavelByMatricula(idMatricula: $idMatricula, responsavel: $responsavel) {
                    id
                    nome
                    cpf
                    grauParentesco
                    grauParentescoNome
                    celular
                    telefoneFixo
                    email
                    cep
                    cidade
                    bairro
                    endereco
                    numero
                    complemento
                    estado
                    emancipado
                    novaSenha
                    senhaSite
                    senha
                    dataNascimento
                    cadastroPendente
                    urlImagemPerfil
                    rg
                    rateio
                    codigoErp
                    codigoCliente
                    urlContrato
                    responsavelPF
                    tipoResponsavel
                }
            }
        `,
        variables: {
            idMatricula,
            responsavel
        }
    })

    dispatchGraphError(errors);

    return criarMovimentacoesResponsavelByMatricula;
}

export function FinalizarRateio(idMatricula, responsavel) {
    responsavel = responsavel.map((item) => {
        delete item.__typename;
        item.rateio = String(item.rateio);
        return item;
    });

    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    return client.mutate({
        mutation: gql`
        mutation FinalizarRateio($idMatricula: String, $responsavel: [ResponsavelRequest]) {
            finalizarRateio(idMatricula: $idMatricula, responsavel: $responsavel) {
                value
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
        variables: {
            idMatricula,
            responsavel,
        }
    }).then((res) => {
        const {
            data: {
                finalizarRateio: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

