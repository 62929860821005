import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import Card from "@mui/material/Card";
import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import useCsat from "../hooks/use-csat";
import Csat from "../components/Csat/Csat";
import {showSelectMatriculasState} from "../recoil/atoms/showSelectMatriculasState";
import {showSelectColaboradorState} from "src/recoil/atoms/showSelectColaboradorState";

export default function withUnidade(Component, title = '', csatName = '') {
	return function _hoc(props) {
		const unidade = useRecoilValue(selectedUnidadeState);
		const setShowSelectmatriculas = useSetRecoilState(showSelectMatriculasState);
		const setShowSelectcolaoradores = useSetRecoilState(showSelectColaboradorState);
		const {
			addAnsweredCsat,
			isCsatVisible,
			handleShowCsat
		} = useCsat(csatName);
		
		useEffect(() => {
			setShowSelectmatriculas(false);
			setShowSelectcolaoradores(false);
		}, [])
		
		return (
			<>
				<Csat
					show={isCsatVisible}
					scoreTitle="O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?"
					onSubmit={() => addAnsweredCsat(csatName)}
					onClosed={() => addAnsweredCsat(csatName)}
				/>
				
				<Card
					sx={{
						m: 2,
						position: 'relative',
						minHeight: '600px',
						padding: '20px',
					}}
				>
					<Helmet>
						<title>{title ? `${title} | ` : ''}Secretaria
							Web</title>
					</Helmet>
					{!unidade ? (
						<>
							Selecione a unidade e matrícula
						</>
					) : (
						<Component
							{...props}
							Unidade={unidade}
							handleShowCsat={handleShowCsat}
						/>
					)}
				</Card>
			</>
		);
	}
}
