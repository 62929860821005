import React, {Component} from "react";
import * as Message from '../shared/Message';
import * as _conciliacaoMatriculaService from "./../../service/painel-conciliacao-matricula-service.js";
import includesSearch from "../../utils/includesSearch";

export class PainelConciliacaoMatricula extends Component {
  static displayName = PainelConciliacaoMatricula.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      filters: {
        pesquisa: "",
        ano: "",
        possuiOrdemVenda: "",
        possuiFicha: "",
        possuiTransacao: "",
        possuiServico: ""
      },
      result: [],
      loadingValidar: []
    };
  }

  async componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ isLoading: true});
    var resultDados = await _conciliacaoMatriculaService.GetAll();
    this.setState({ isLoading: false });

    if (resultDados != null && resultDados != undefined) {
      if (resultDados.value != null && resultDados.value != undefined && resultDados.value.length > 0) {
        this.setState({ result: resultDados.value });
      } else {
        Message.ExibeMensagem("Nenhuma matrícula encontrada", 2);
      }

      Message.ExibeListMensagem(resultDados.messages);

    } else {
      Message.ExibeMensagem("houve problemas ao identificar as matriculas", 2);
    }
  }

  renderBadge(status = "") {
    switch (String(status).toLocaleLowerCase()) {
      case "sim":
        return <span className="badge badge-success">Sim</span>;
      case "não":
        return <span className="badge badge-danger">Não</span>;
      default:
        return status;
    }
  }

  handleFilterChange = (event) => {
    const { name, value: originalValue, type } = event.target;

    let value = originalValue;

    switch (type) {
      case "checkbox":
        value = event.target.checked ? "Não" : "";
        break;
    }


    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value
      }
    });
  }

  handleValidarClick = (matriculaId, logId = null) => async (event) => {
    event.preventDefault();

    if (this.state.loadingValidar.includes(matriculaId)) {
      return;
    }

    this.setState({
      loadingValidar: [
        ...this.state.loadingValidar,
        matriculaId
      ]
    });

    const result = await _conciliacaoMatriculaService.Validar(matriculaId);

    if (result != null && "value" in result && result.value === true) {
      Message.ExibeMensagem("Matricula possui todos os itens criados, removida da listagem!", '0', true);
      this.setState({
        result: this.state.result.filter(item => item.id !== logId)
      })
    } else {
      Message.ExibeMensagem("Matricula não possui todos os itens criados!", '2', true);
    }

    this.setState({
      loadingValidar: this.state.loadingValidar.filter(item => item !== matriculaId)
    });
  }


  handleVerificarClick = (codigoErp) => async (event) => {
    event.preventDefault();

    if (this.state.loadingValidar.includes(codigoErp)) {
      return;
    }

    this.setState({
      loadingValidar: [
        ...this.state.loadingValidar,
        codigoErp
      ]
    });

    const result = await _conciliacaoMatriculaService.Verificar(String(codigoErp).trim());

    if (result != null && "value" in result && result.value === true) {
      Message.ExibeMensagem("Matricula possui todos os itens criados, removida da listagem!", '0', true);
      this.setState({
        result: this.state.result.filter(item => item.matricula !== codigoErp)
      })
    } else {
      Message.ExibeMensagem("Matricula não possui todos os itens criados!", '2', true);
      await this.loadData();
    }

    this.setState({
      loadingValidar: this.state.loadingValidar.filter(item => item !== codigoErp)
    });
  }

  applyFilters = (item) => {
    const {
      filters: {
        pesquisa,
        ano,
        possuiOrdemVenda,
        possuiFicha,
        possuiTransacao,
        possuiServico
      },
    } = this.state;

    let canShow = true;

    if (pesquisa) {
      canShow = canShow && includesSearch(item.aluno, pesquisa) || includesSearch(item.matricula, pesquisa);
    }

    if (ano) {
      canShow = canShow && item.ano.toLowerCase().includes(ano.toLowerCase());
    }

    if (possuiOrdemVenda) {
      canShow = canShow && item.ordemVenda.toLowerCase().includes(possuiOrdemVenda.toLowerCase());
    }

    if (possuiFicha) {
      canShow = canShow && item.ficha.toLowerCase().includes(possuiFicha.toLowerCase());
    }

    if (possuiTransacao) {
      canShow = canShow && item.financeiro.toLowerCase().includes(possuiTransacao.toLowerCase());
    }

    if (possuiServico) {
      canShow = canShow && item.servico.toLowerCase().includes(possuiServico.toLowerCase());
    }

    return canShow;
  }

  renderValidarButton(matriculaId, logId) {
    return (
      <button
        className="btn btn-primary btn-sm btn-block"
        onClick={this.handleValidarClick(matriculaId, logId)}
        disabled={this.state.loadingValidar.includes(matriculaId)}
      >
        {this.state.loadingValidar.includes(matriculaId) ? (
          <span>Validando...</span>
        ) : (
          <span>Validar</span>
        )}
      </button>
    )
  }

  render() {
    const {
      result,
      filters: {
        pesquisa,
        ano,
        possuiOrdemVenda,
        possuiFicha,
        possuiTransacao,
        possuiServico
      },
      isLoading
    } = this.state;

    const filtered = (result || []).filter(this.applyFilters);

    const countSemOV = (filtered || []).filter(item => item.ordemVenda === "Não").length;
    const countSemFicha = (filtered || []).filter(item => item.ficha === "Não").length;
    const countSemTransacao = (filtered || []).filter(item => item.financeiro === "Não").length;
    const countSemServico = (filtered || []).filter(item => item.servico === "Não").length;
    const countAnos = (filtered || []).map(item => item.ano === ano).length;

    const anosUnicos = [...new Set((result || []).map(item => item.ano))]

    return (
      <div className="panel">
        <h2 className="tituloCss">Painel de Conciliação de Matricula</h2>
        <div className="panel-body p-25 mb-3">
          <fieldset className='p-b-10 p-t-10'>
            <legend className="pull-left width-full">Filtros</legend>
            <div className="row">
              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>Pesquisa (Matricula CRM)</label>
                  <input
                    className="form-control"
                    name="pesquisa"
                    value={pesquisa}
                    onChange={this.handleFilterChange}
                  />
                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group" style={{paddingTop: "20px"}}>
                  <button
                    className="btn btn-primary btn-sm btn-block"
                    onClick={this.handleVerificarClick(pesquisa)}
                    disabled={pesquisa.length === 0 || this.state.loadingValidar.includes(pesquisa)}
                  >
                    {this.state.loadingValidar.includes(pesquisa) ? (
                      <span>Verificando...</span>
                    ) : (
                      <span>Verificar</span>
                    )}
                  </button>
                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>Ano Letivo ({countAnos})</label>
                  <select
                    className="form-control"
                    name="ano"
                    value={ano}
                    onChange={this.handleFilterChange}
                  >
                    <option value="">Todos</option>
                    {anosUnicos.map(ano => (
                      <option value={ano}>{ano}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>
                    Sem OV ({countSemOV})
                    <br/>
                    <input
                      className="checkbox"
                      name="possuiOrdemVenda"
                      value={possuiOrdemVenda}
                      type="checkbox"
                      disabled={!countSemOV}
                      onChange={this.handleFilterChange}
                    />
                  </label>

                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>
                    Sem Ficha ({countSemFicha})
                    <br/>
                    <input
                      className="checkbox"
                      name="possuiFicha"
                      value={possuiFicha}
                      type="checkbox"
                      disabled={!countSemFicha}
                      onChange={this.handleFilterChange}
                    />
                  </label>

                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>
                    Sem Transação ({countSemTransacao})
                    <br/>
                    <input
                      className="checkbox"
                      name="possuiTransacao"
                      value={possuiTransacao}
                      type="checkbox"
                      disabled={!countSemTransacao}
                      onChange={this.handleFilterChange}
                    />
                  </label>

                </div>
              </div>

              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>
                    Sem Serviço ({countSemServico})
                    <br/>
                    <input
                      className="checkbox"
                      name="possuiServico"
                      value={possuiServico}
                      type="checkbox"
                      disabled={!countSemServico}
                      onChange={this.handleFilterChange}
                    />
                  </label>

                </div>
              </div>
            </div>
            <hr />
          </fieldset>

          {isLoading && (
            <h4>Carregando... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
          )}

          {!isLoading && (
            <div className="col-md-12 col-lg-12">
              <table className="table">
                <thead>
                <tr>
                  <th scope="col">Matricula</th>
                  <th scope="col">Aluno</th>
                  <th scope="col">Unidade</th>
                  <th scope="col">Ano</th>
                  <th scope="col">Ordem de Venda</th>
                  <th scope="col">Ficha</th>
                  <th scope="col">Transações</th>
                  <th scope="col">Serviço</th>
                  <th scope="col col-3">Ações</th>
                </tr>
                </thead>
                <tbody>
                {filtered.map((item, index) => {
                  return (
                    <tr
                      key={item.matriculaId}
                    >
                      <td>{item.matricula}</td>
                      <td>{item.aluno}</td>
                      <td>{item.unidade}</td>
                      <td>{item.ano}</td>
                      <td>{this.renderBadge(item.ordemVenda)}</td>
                      <td>{this.renderBadge(item.ficha)}</td>
                      <td>{this.renderBadge(item.financeiro)}</td>
                      <td>{this.renderBadge(item.servico)}</td>
                      <td>
                        {this.renderValidarButton(item.matriculaId, item.id)}
                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          )}
        </div>

      </div>
    );
  }
}

const PainelConciliacao = () => {
  return <PainelConciliacaoMatricula />;
}

export default PainelConciliacao;
