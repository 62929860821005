import React, { useEffect, useState } from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import withAppContext from "../../hocs/withAppContext";
import moment from 'moment'
import * as _responsavelService from "../../service/responsavel-service";
import * as _matriculaService from "../../service/matricula-service";
import * as Message from "../../components/shared/Message";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation';
import { ResponsavelResponse } from 'seb-graph-api-types/generated';

import './styles.css'

const DeclaracaoDeBolsa = (props: any) => {
    const { Matricula, Unidade } = props;
    const [responsavel, setResponsavel] = useState<ResponsavelResponse>({nome: '' });
    const [errorText, setErrorText] = useState('');
    const [isloading, setIsLoading] = useState(true);
    const [erroRequisicao, setErroRequisicao] = useState(false);
    const data = moment().format('DD/MM/YYYY')

    const printPDF = () => {
        window.print();
    };
    useEffect(() => {
        setErroRequisicao(false);
        setIsLoading(true);
        GetResponsavelByMatricula();
    }, [Matricula])

    const GetResponsavelByMatricula = async () => {
        var responsavel = await _responsavelService.GetResponsavelByMatricula(Matricula.idMatricula);
        if (responsavel) {
            setResponsavel(responsavel);
            setIsLoading(false);
        } else {
            Message.ExibeMensagem("O responsável não foi encontrado!", "2", false);
            setErroRequisicao(true);
            setErrorText('O responsável não foi encontrado!');
        } 
    }

    if (erroRequisicao) {
        return <ErrorMessage message={errorText} />
    }
    if (isloading) {
        return (<LoadingAnimation> </LoadingAnimation>)
    }
    return (
        <>
            <div className='print-content'>
                <button className="print-button" onClick={printPDF}>Imprimir</button>
            </div>
            <div className="declaracao-component">
                <div className="declaracao-container">
                    <h4>DECLARAÇÃO</h4>
                    <div className='texto-variavel-paragrafo'>Sr. (s) Pai (s) ou responsáveis:&nbsp;<div>{responsavel.nome}</div></div>
                    <div className='texto-variavel-paragrafo'>Aluno: &nbsp;<div>{Matricula.estudante_Name}</div></div>
                    <div className='texto-variavel-paragrafo'>MATRÍCULA: :&nbsp;<div>{Matricula.codigoMatricula}</div>. </div>
                    <p>O Ministério da Educação, através da Portaria INEP 156 de 20 de outubro de 2004, determinou que as escolas incluíssem na ficha de matrícula a informação sobre cor/raça dos alunos.</p>
                    <p>Esta informação deve ser obtida da auto-declaração do aluno maior de 16 anos ou declaração dos pais ou responsáveis no caso do aluno abaixo desta idade.</p>
                    <div className='texto-com-underline'>
                        <p >O mesmo declara-se:&nbsp;</p>
                        <hr className='half-underline'/>
                    </div>
                    <div className='texto-separado'>
                        <div className='texto-variavel-paragrafo'>LOCAL:&nbsp;<div>{Unidade.cidade}/{Unidade.estado}</div></div>
                        <div className='texto-variavel-paragrafo'>DATA:&nbsp;<div>{data}</div></div>
                    </div>
                    <div className="declaracao-footer">
                        <div>
                            <br />
                            <hr />
                            <div className="legenda-footer">
                                <div className='texto-variavel-paragrafo'><div>{responsavel.nome}</div></div>
                                <p>Responsável Legal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withAppContext(withUnidadeMatricula(DeclaracaoDeBolsa, 'Declaracao De Bolsa'));