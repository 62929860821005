import React, {useContext, useMemo} from "react";
import {Route} from "react-router-dom";
import {RouteProps} from "react-router";
import {AuthContext} from "./context/AuthContext";
import {Permission} from "./utils/hasPathPermission";
import {useSetRecoilState} from "recoil";
import sectionsState from "./recoil/atoms/sectionsState";
import {Section} from "./hooks/use-can-view-section";

export interface ContextProps {
  listPermissions: Array<Permission>
}

interface ViewRouterProps {
  routes: Array<Section>
  middlewares?: ((route: RouteProps, context: ContextProps) => boolean)[]
}

const ViewRouter: React.FC<ViewRouterProps> = props => {
  const {
    routes,
    middlewares = []
  } = props;
  const context = useContext(AuthContext);
  const setSections = useSetRecoilState(sectionsState);
  
  const filtered = useMemo(() => {
    const filtered = [];
    const r = [];
    
    routes.forEach(route => {
      if (route.items && route.items.length > 0) {
        const newItems = [];
        
        route.items.forEach(item => {
          const subItems = [];
          let can = false;
          if (item.items) {
            item.items.forEach(i => {
              if (!can) {
                can = (middlewares || []).every(middleware => middleware(i, context) === true)
              }
              
              if ((middlewares || []).every(middleware => middleware(i, context) === true)) {
                subItems.push(i)
                filtered.push(i)
              }
            })
          }
          
          if (!can) {
            can = (middlewares || []).every(middleware => middleware(item, context) === true)
            
            if (can) {
              newItems.push(item)
              filtered.push(item)
            }
          } else if (subItems.length > 0) {
            newItems.push({
              ...item,
              items: subItems
            })
          }
        });
        
        if (newItems.length > 0) {
          r.push({
            ...route,
            items: newItems
          })
        }
      }
    });
    
    setSections(r);
    
    return filtered;
  }, [routes, middlewares, context]);
  
  return filtered.map((props, i) => {
    return (
      <Route
        key={i}
        exact={props.exact}
        path={props.path}
        component={props.component}
      />
    )
  })
}

export default ViewRouter;
