import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import {Layout} from './components/Layout';
import updateSiteTheme from "./utils/updateSiteTheme";
import * as _blackListService from "./service/blacklist-service";
import {useDialog} from "./hooks/use-dialog";
import ShortCuts from "./components/ShortCuts/ShortCuts";
import nprogress from "nprogress";
import {useAnalytics} from "./hooks/use-analytics";
import {gtmConfig} from "./config";

import * as Yup from "yup";
import {pt} from "yup-locale-pt";
import ViewRouter from "./ViewRouter";
import routes from "./routes";
import viewMiddleware from "./middlewares/view-middleware";
import guardianViewMiddleware from "./middlewares/guardian-view-middleware";

nprogress.configure({ showSpinner: false });

Yup.setLocale(pt);

export const AppContext = React.createContext({
	BlockedResources: null,
});

function App() {
	const dialog = useDialog();
	let location = useLocation();
	useAnalytics(gtmConfig);

	useEffect(() => {
		nprogress.start();
		nprogress.done();
	}, [location.pathname]);

	React.useEffect(() => {
		updateSiteTheme();
	}, []);

	const [blockedResources, setBlockedResources] = useState(null);

	React.useEffect(() => {
		if (blockedResources === null && window.apolloClient) {
			const fetchBlockedResources = async () => {
				_blackListService.GetList(`${process.env.REACT_APP_CLIENT_ID}`).then(data => {
					setBlockedResources(data?.value || []);
				}).catch(error => console.log(error));
			}

			fetchBlockedResources();
		}
	}, [blockedResources, setBlockedResources, window.apolloClient]);

	return (
		<AuthProvider>
			<AppContext.Provider value={{blockedResources, setBlockedResources}}>
				<Layout>
					<ViewRouter
						routes={routes}
						middlewares={[
							viewMiddleware,
							guardianViewMiddleware,
						]}
					/>
				</Layout>

				<ShortCuts
					commands={[
						{
							handler: e => e.ctrlKey && e.key === '/',
							onMatch: dialog.handleOpen
						}
					]}
				/>
			</AppContext.Provider>
		</AuthProvider>
	);
}

export default App;

