import {RouteProps} from "react-router";
import {ContextProps} from "../ViewRouter";
import {getRecoil} from "recoil-nexus";
import selectedUnidadePossuiFacialSelector from "../recoil/selectors/selectedUnidadePossuiFacialSelector";
import selectedUnidadePossuiCarteirinhaSelector from "../recoil/selectors/selectedUnidadePossuiCarteirinhaSelector";

export default function guardianViewMiddleware(route: RouteProps, context: ContextProps): boolean {
  switch (route.path) {
    case '/reconhecimento-facial':
    case '/reconhecimento-facial/alunos':
    case '/reconhecimento-facial/responsaveis':
    case '/reconhecimento-facial/colaboradores':
    case '/reconhecimento-facial/publicidade':
    case '/reconhecimento-facial/permissao-horarios':
    case '/reconhecimento-facial/visitantes':
      const possui = getRecoil(selectedUnidadePossuiFacialSelector)
      console.log("possui", possui)
      return possui
    case '/declaracoes/carteirinha-escolar':
      return getRecoil(selectedUnidadePossuiCarteirinhaSelector)
    default:
      return true;
  }
}
