export interface Permission {
  id?: string
  permissionId?: string
  permissionName?: string
  roleName?: string
  systemId?: string
  systemName?: string
}


export default function hasPathPermission(path: string, permissions: Permission[]): {
  read: boolean;
  write: boolean;
} {
  const parts = String(path).trim().toLowerCase().split('/').filter(p => p);
  const permissionNeeded = parts.reduce((acc, path) => {
    const isUUID = path.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
    const part = isUUID ? '{guid}' : path;
    if (acc === '') return part;
    return `${acc}.${part}`;
  }, '');
  const permissionNeededGuid = parts.reduce((acc, path) => {
    const part = path
    if (acc === '') return part;
    return `${acc}.${part}`;
  }, '');
  const userCan = (permission: string) => {
    return permissions.map(p => p?.permissionName).includes(permission)
  }
  
  const somePermission = (...permissions: string[]) => {
    return permissions.some(userCan)
  }
  
  return {
    read: somePermission(`${permissionNeeded}.list`, `${permissionNeededGuid}.list`),
    write: somePermission(`${permissionNeeded}.write`, `${permissionNeededGuid}.write`),
  }
}
