import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function ReplayIntegrations(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            replayIntegrationErrors
        },
        errors
    } = await client.mutate({
        mutation : gql`
            mutation ReplayIntegrationErrors($request: IntegrationReplayRequest) {
                replayIntegrationErrors(request: $request) {
                    statusResult
                }
            }
        `,
        variables: {
            request
        }
    })

    dispatchGraphError(errors);

    return replayIntegrationErrors;
}

export async function GetIntegracaoListFilterByDates(startDate, endDate) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getIntegrationErrors
        },
        errors
    } = await client.query({
        query : gql`
            query GetIntegrationErrors($startDate: String, $endDate: String) {
                getIntegrationErrors(startDate: $startDate, endDate: $endDate) {
                    integrationErrorsDetailsList {
                        codigoMatricula
                        codigoMatriculaErp
                        turma_Name
                        codigoCliente
                        estudante_Name
                        anoLetivo_Name
                        periodo
                        status
                        servico
                        integrationErrorLogList {
                            logId
                            tipoProcessoDescricao
                            mensagemErro
                            payload
                        }
                    }
                }
            }
        `,
        variables: {
            startDate,
            endDate
        }
    })

    dispatchGraphError(errors);

    return getIntegrationErrors;
}



export async function GetIntegracaoTotalList(date) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getIntegracaoTotalList
        },
        errors
    } = await client.query({
        query : gql`
            query GetIntegracaoTotalList($date: String) {
                getIntegracaoTotalList(date: $date) {
                    adimplente
                    anoLetivo_Id
                    anoLetivo_Name
                    bloqueioColaborador
                    bloqueioContratacaoUnidade
                    bloqueioPedagogico
                    bloqueioSistemico
                    codigobarrasBoleto
                    codigobarrasBoletoReserva
                    curso_Id
                    curso_Name
                    dadosAluno {
                        id
                        nome
                        email
                        cpf
                        rg
                        dataNascimento
                        urlImagemPerfil
                        codigoErp
                        codigoClient
                        razaoStatusValue
                        statusValue
                        razaoStatusNome
                        statusNome
                        cadastroPendente
                        criadoEm
                    }
                    dataAceite
                    dataDeCancelamento
                    dataUltimoPagamento
                    urlBoletoReserva
                    id
                    unidade_Name
                    unidade_Id
                    servico_Name
                    servico_Id
                    estudante_Name
                    estudante_Id
                    responsavelContractual_Name
                    responsavelContractual_Id
                    turma_Name
                    turma_Id
                    segmento_Name
                    segmento_Id
                    periodo_Value
                    status_Value
                    razaoStatus_Value
                    razaoStatus_Nome
                    tipoMatricula_Value
                    dataDaMatricula
                    urlContrato
                    nomeLandingPage
                    evolucaoMatricula
                    iPdeOrigemAceite
                    codigoMatricula
                    codigoMatriculaErp
                    valorReserva
                    descontoBolsaEstudo
                    descontoBolsaIrmaos
                    descontoBolsaNova
                    tipoMatricula {
                        valor
                        descricao
                    }
                    itensContrato {
                        id
                        nome
                    }
                    motivoDoCancelamento
                }
            }
        `,
        variables: {
            date,
        }
    })

    dispatchGraphError(errors);

    return getIntegracaoTotalList;
}

export async function Replay(codigoMatricula) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getIntegracapReplay
        },
        errors = [],
    } = await client.query({
        query : gql`
            query GetIntegracapReplay($codigoMatricula: String) {
                getIntegracapReplay(codigoMatricula: $codigoMatricula)
            }
        `,
        variables: {
            codigoMatricula
        }
    }).catch(errors => ({
        data: {
            getIntegracapReplay: {}
        },
        errors
    }))

    dispatchGraphError(errors);

    if (errors.length) {
        const error = errors[0];
        const message = error?.extensions?.response?.body || "Não foi possivel realizar esta ação."
        console.error(message)
        return {
            value: message
        };
    }

    return getIntegracapReplay;
}

export function Contrato(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation AlteraItemContrato($request: ItemContratoAtualizacaoRequest) {
                alteraItemContrato(request: $request) {
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                    value {
                        contratoHTML
                        id
                        servicoUnidadeContratoHTML
                        itemsContratoEditaveisList{
                            idMatricula
                            criarContrato
                            descricaoPagamentoMensalidade
                            descricaoPagamentoEntrada
                            descricaoValoresContratados
                            nomeServico
                            nomeAluno
                            nomeResponsavelContratual
                            enderecoResponsavel
                        }
                    }
                }
            }
        `,
        variables: {
            request,
        }
    }).then((res) => {
        const {
            data: {
                alteraItemContrato: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

export function ReplayIntegracaoParcial(matriculaId) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayIntegracaoParcial($matriculaId: String) {
                enviaReplayIntegracaoParcial(matriculaId: $matriculaId) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            matriculaId,
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayIntegracaoParcial: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

export function ReplayTotalUpSelling(matriculaCrm, diaVencimento) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayTotalUpSelling($matriculaCrm: String, $diaVencimento: String) {
                enviaReplayTotalUpSelling(matriculaCrm: $matriculaCrm, diaVencimento: $diaVencimento) {
                    messages {
                        text
                        typeMessage
                    }
                    value
                    statusCode
                }
            }
        `,
        variables: {
            matriculaCrm,
            diaVencimento,
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayTotalUpSelling: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

export function ReplayParcialUpSelling(
  {
      codigoMatricula,
      qntParcela,
      dataReferencia,
      envioItems
  }
) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayParcialUpSelling($request: ReplayParcialUpSellingRequest) {
                enviaReplayParcialUpSelling(request: $request) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            request: {
                codigoMatricula,
                qntParcela,
                dataReferencia,
                envioItems
            },
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayParcialUpSelling: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}


export function ReplayParcialRegular(
  {
      codigoMatricula,
      qntParcelaRegular: qntParcela,
      qntParcelaMaterial,
      qntParcelaRegularEntrada: qntParcelaEntrada,
      percentualDescontoEntrada,
      dataReferencia,
      envioItems
  }
) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayParcialRegular($request: ReplayParcialRegularRequest) {
                enviaReplayParcialRegular(request: $request) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            request: {
                codigoMatricula,
                qntParcela,
                qntParcelaMaterial,
                qntParcelaEntrada,
                percentualDescontoEntrada,
                dataReferencia,
                envioItems
            },
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayParcialRegular: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

export function ReplayParcialReserva(
  {
      codigoMatricula,
      qntParcela,
      dataReferencia,
      envioItems
  }
) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayParcialReserva($request: ReplayParcialReservaRequest) {
                enviaReplayParcialReserva(request: $request) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            request: {
                codigoMatricula,
                qntParcela,
                dataReferencia,
                envioItems
            },
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayParcialReserva: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}

export function ReplayParcialMaterial(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation : gql`
            mutation EnviaReplayParcialMaterial($request: [ReplayParcialMaterialRequest]) {
                enviaReplayParcialMaterial(request: $request) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            request
        }
    }).then((res) => {
        const {
            data: {
                enviaReplayParcialMaterial: {
                    messages = [],
                    statusCode = 200,
                    value
                }
            },
            errors
        } = res

        dispatchGraphError(errors);

        return {
            messages,
            value: messages.length ? messages[0].text : value,
            statusCode,
        };
    }).catch(console.error)
}