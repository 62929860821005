import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Modal } from "../modal";
import { Grid, Stack, Typography, Container, TextField, Checkbox, Button, IconButton } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import DeleteIcon from "@mui/icons-material/Delete";
import type { Intervals } from "../../types/guardian-types";

export const diasSemana = [
  { name: "Domingo", value: "isSunday" },
  { name: "Segunda", value: "isMonday" },
  { name: "Terça", value: "isTuesday" },
  { name: "Quarta", value: "isWednesday" },
  { name: "Quinta", value: "isThursday" },
  { name: "Sexta", value: "isFriday" },
  { name: "Sábado", value: "isSaturday" },
]

const initialInterval = [
  {
    id: 1,
    startTime: "00:00:00",
    endTime: "00:00:00",
    isSunday: false,
    isMonday: false,
    isTuesday: false,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
  },
]

const initialCheckedDays = {
  isSunday: false,
  isMonday: false,
  isTuesday: false,
  isWednesday: false,
  isThursday: false,
  isFriday: false,
  isSaturday: false,
}

interface DiasSemana {
  isSunday: boolean
  isMonday: boolean
  isTuesday: boolean
  isWednesday: boolean
  isThursday: boolean
  isFriday: boolean
  isSaturday: boolean
}


interface Props {
  intervals: Intervals[]
  open: boolean
  selectedDay: string
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  handleSave: (intervals: Intervals[]) => void
  isEntrada: boolean
}

const HorarioInputs = (props: Props) => {
  const { intervals, open, setOpen, isEntrada, selectedDay, handleSave } = props
  const [selectedDayInterval, setSelectedDayInterval] = useState<Intervals[]>(initialInterval)
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [localIntervals, setLocalIntervals] = useState<Intervals[]>(intervals)
  const [tempInputValues, setTempInputValues] = useState<{ [key: string]: string }>({})
  const [checkedDays, setCheckedDays] = useState<DiasSemana>(initialCheckedDays)

  const handleLocalClose = () => {
    setSelectedDayInterval(intervals)
    setCheckedDays(initialCheckedDays)
    setOpen(false)
  }

  const isKeySelected = (selectedDayIntervals: Intervals[], key = "", isAll = false): boolean => {
    if (isAll) {
      let isAllSelected = true
      diasSemana.forEach((dia) => {
        if (!selectedDayIntervals.every((interval) => interval[dia.value] === true)) {
          isAllSelected = false
        }
      })
      return isAllSelected
    } else {
      return selectedDayIntervals.every((interval) => interval[key] === true)
    }
  }

  useEffect(() => {
    if (open) {
      const localIntervals = intervals.map((interval) => ({ ...interval }))
      setLocalIntervals(localIntervals)
      setHasChanges(false)
      setTempInputValues({})
    }
  }, [open, selectedDay, intervals])

  useEffect(() => {
    const filteredIntervals = localIntervals.filter((interval) => interval[selectedDay] === true)
    if (filteredIntervals.length === 0) {
      const emptyInterval = {
        id: Math.max(...localIntervals.map((interval) => interval.id), 0) + 1,
        startTime: "00:00:00",
        endTime: "00:00:00",
        isSunday: false,
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
      }
      emptyInterval[selectedDay] = true
      filteredIntervals.push(emptyInterval)
    }
    const checkedValues: DiasSemana = diasSemana.reduce((acc, dia) => {
      const isChecked = isKeySelected(filteredIntervals, dia.value, false)
      return { ...acc, [dia.value]: isChecked }
    }, initialCheckedDays)
    setCheckedDays(checkedValues)
    setSelectedDayInterval(filteredIntervals)
  }, [open])

  const removerPeriodo = (e: MouseEvent, id: number, selectedDay: string) => {
    e.preventDefault()
    const newIntervals = localIntervals.map((interval) => {
      if (interval.id === id) {
        diasSemana.forEach((dia) => {
          if (checkedDays[dia.value]) {
            interval[dia.value] = false
          }
        })
      }
      return interval
    }).filter((interval) => diasSemana.some((dia) => interval[dia.value]))
    const filteredIntervals = newIntervals.filter((interval) => interval[selectedDay] === true)
    if (filteredIntervals.length === 0) {
      const emptyInterval = {
        id: Math.max(...newIntervals.map((interval) => interval.id), 0) + 1,
        startTime: "00:00:00",
        endTime: "00:00:00",
        isSunday: false,
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
      }
      emptyInterval[selectedDay] = true
      newIntervals.push(emptyInterval)
      setSelectedDayInterval([emptyInterval])
    } else {
      setSelectedDayInterval(filteredIntervals)
    }

    setLocalIntervals(newIntervals)
    setHasChanges(true)
  }

  const adicionarPeriodo = (e: MouseEvent, selectedDay: string) => {
    e.preventDefault()
    const newInterval = {
      id: localIntervals.length + 1,
      startTime: "00:00:00",
      endTime: "00:00:00",
      isSunday: false,
      isMonday: false,
      isTuesday: false,
      isWednesday: false,
      isThursday: false,
      isFriday: false,
      isSaturday: false,
    }
    newInterval[selectedDay] = true
    const duplicateIntervals = []
    const updatedIntervals = localIntervals.map((interval, index) => {
      if (interval[selectedDay]) {
        duplicateIntervals.push({
          ...newInterval,
          startTime: interval.startTime,
          endTime: interval.endTime,
          [selectedDay]: true,
          id: localIntervals.length + index + 2,
        })
        const updatedInterval = { ...interval }
        updatedInterval[selectedDay] = false
        return updatedInterval
      }
      return interval
    })
    const newIntervals = [...updatedIntervals, ...duplicateIntervals, newInterval]
    const newSelectedDayInterval = [...selectedDayInterval, ...duplicateIntervals, newInterval]
    setSelectedDayInterval(newSelectedDayInterval)
    setLocalIntervals(newIntervals)
    checkForChanges(newIntervals)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    const { name, value } = event.target
    const digitsOnly = value.replace(/[^\d]/g, "").substring(0, 4)

    const fieldKey = `${id}-${name}`

    setTempInputValues({
      ...tempInputValues,
      [fieldKey]: digitsOnly,
    })
  }

  const handleInputBlur = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    selectedDay: string,
  ) => {
    const { name } = event.target
    const fieldKey = `${id}-${name}`

    const rawValue = tempInputValues[fieldKey] || ""

    if (!rawValue || rawValue === "")
      return

    let formattedValue = "00:00:00"
    let hours = 0
    let minutes = 0

    if (rawValue.length === 1) {
      hours = Number.parseInt(rawValue, 10)
      formattedValue = `0${rawValue}:00:00`
    } else if (rawValue.length === 2) {
      hours = Number.parseInt(rawValue, 10)
      formattedValue = `${rawValue}:00:00`
    } else if (rawValue.length === 3) {
      hours = Number.parseInt(rawValue.substring(0, 2), 10)
      minutes = Number.parseInt(rawValue.substring(2, 3), 10)
      formattedValue = `${rawValue.substring(0, 2)}:0${rawValue.substring(2, 3)}:00`
    } else if (rawValue.length === 4) {
      hours = Number.parseInt(rawValue.substring(0, 2), 10)
      minutes = Number.parseInt(rawValue.substring(2, 4), 10)
      formattedValue = `${rawValue.substring(0, 2)}:${rawValue.substring(2, 4)}:00`
    }

    if (hours >= 24) {
      formattedValue = "23:59:00"
    }
    else if (minutes >= 60) {
      if (hours === 23) {
        formattedValue = "23:59:00"
      } else {
        formattedValue = `${hours.toString().padStart(2, "0")}:59:00`
      }
    }

    const newTempValues = { ...tempInputValues }
    delete newTempValues[fieldKey]
    setTempInputValues(newTempValues)

    updateIntervalValue(id, name, formattedValue, selectedDay)
  }

  const updateIntervalValue = (id: number, name: string, value: string, selectedDay: string) => {
    const newSelectedIntervals = selectedDayInterval.map((interval) => {
      if (interval.id === id) {
        return { ...interval, [name]: value }
      }
      return interval
    })

    const newLocalIntervals = localIntervals.map((interval) => {
      if (interval.id === id) {
        return { ...interval, [name]: value }
      }
      return interval
    })

    if (!localIntervals.some((interval) => interval.id === id)) {
      const intervalToAdd = newSelectedIntervals.find((interval) => interval.id === id)
      if (intervalToAdd) {
        newLocalIntervals.push(intervalToAdd)
      }
    }

    setSelectedDayInterval(newSelectedIntervals)
    setLocalIntervals(newLocalIntervals)
    setHasChanges(true)
  }

  const handleMultipleChanges = (event: MouseEvent, value: boolean, isAll: boolean, selectedDay: string) => {
    event.preventDefault()
    let newIntervals = [...localIntervals]
    const name = (event.target as HTMLInputElement)?.name
    if (isAll) {
      // remover todos os intervalos que não fazem parte do dia selecionado
      newIntervals = newIntervals.filter((interval) => interval[selectedDay] !== false)
      diasSemana.forEach((dia) => {
        newIntervals.forEach((interval) => {
          interval[dia.value] = value
        })
      })
      if (!value)
        newIntervals.forEach((interval) => {
          interval[selectedDay] = true
        })
      setSelectedDayInterval(newIntervals)
      setLocalIntervals(newIntervals)
    } else {
      newIntervals.forEach((interval) => {
        if (interval[name]) {
          interval[name] = false
        }
        if (interval[selectedDay]) {
          interval[name] = value
        }
      })
      const filteredIntervals = newIntervals.filter((interval) => interval[selectedDay] === true)
      setSelectedDayInterval(filteredIntervals)
    }
    checkForChanges(newIntervals)
  }

  const handleLocalSave = () => {
    // filtrar os intervalos que não possuem nenhum dia selecionado
    // filtrar os intervalos que possuem startTime e endTime iguais a 00:00:00
    let filteredIntervals = localIntervals
      .filter((interval) => !(interval.startTime === '00:00:00' && interval.endTime === '00:00:00'))
      .filter((interval) => diasSemana.some((dia) => interval[dia.value]))
      .map((interval) => {
        let intervalIdCounter = interval.id;
        let intervalsIds = new Set();
        localIntervals.forEach((a) => {
          if (intervalsIds.has(a.id)) {
            while (intervalsIds.has(intervalIdCounter)) {
              intervalIdCounter++;
            }
          }
          intervalsIds.add(a.id);
        });
        const intervalToAdd = {
          ...interval,
          id: intervalIdCounter
        }
        return intervalToAdd
      });

    if (!intervals || !intervals.length)
      handleSave(filteredIntervals);
    else {
      const daysAsTrueInFilteredIntervals = new Set<string>();
      filteredIntervals.forEach((interval) => {
        diasSemana.forEach((dia) => {
          if (interval[dia.value] === true) {
            daysAsTrueInFilteredIntervals.add(dia.value);
          }
        });
      });

      if (!filteredIntervals || filteredIntervals.length === 0) {
        const intervalsWithoutMatchingDays = intervals.map((interval) => {
          const intervalCopy = { ...interval };
          diasSemana.forEach((dia) => {
            if (checkedDays[dia.value]) {
              intervalCopy[dia.value] = false;
            }
          });
          return intervalCopy;
        }).filter((interval) => diasSemana.some((dia) => interval[dia.value]));
        setLocalIntervals(intervalsWithoutMatchingDays)
        handleSave(intervalsWithoutMatchingDays)
      } else {
        const intervalsWithoutMatchingDays = intervals.map((interval) => {
          const intervalCopy = { ...interval };
          diasSemana.forEach((dia) => {
            if (daysAsTrueInFilteredIntervals.has(dia.value)) {
              intervalCopy[dia.value] = false;
            }
          });
          return intervalCopy;
        }).filter((interval) => diasSemana.some((dia) => interval[dia.value]));
        intervalsWithoutMatchingDays.filter((interval) => diasSemana.some((dia) => interval[dia.value]));
        const finalIntervals = [...intervalsWithoutMatchingDays, ...filteredIntervals];
        let intervalsIds = new Set();
        finalIntervals.forEach((interval) => {
          let intervalIdCounter = interval.id;
          while (intervalsIds.has(intervalIdCounter)) {
            intervalIdCounter++;
          }
          interval.id = intervalIdCounter;
          intervalsIds.add(intervalIdCounter);
        });
        setLocalIntervals(finalIntervals)
        handleSave(finalIntervals)
      }
    }
    setOpen(false);
  }

  const checkForChanges = (newIntervals: Intervals[]) => {
    if (intervals.length !== newIntervals.length) {
      setHasChanges(true)
      return
    }

    const originalIntervals = JSON.stringify(intervals)
    const currentIntervals = JSON.stringify(newIntervals)
    setHasChanges(originalIntervals !== currentIntervals)
  }

  const getDisplayValue = (interval: Intervals, fieldName: string) => {
    const id = interval.id
    const fieldKey = `${id}-${fieldName}`

    if (fieldKey in tempInputValues) {
      const digits = tempInputValues[fieldKey]
      if (digits.length === 1) return digits
      if (digits.length === 2) return digits
      if (digits.length === 3) return `${digits.substring(0, 2)}:${digits.substring(2, 3)}`
      if (digits.length === 4) return `${digits.substring(0, 2)}:${digits.substring(2, 4)}`
      return digits
    }

    if (interval[fieldName].includes(":")) {
      const parts = interval[fieldName].split(":")
      if (parts[0] === "24") {
        return "23:59"
      }
      return `${parts[0]}:${parts[1]}`
    }

    return interval[fieldName]
  }

  return (
    <Modal
      open={open}
      onClose={() => handleLocalClose()}
      title="Configuração de períodos:"
      disableExpand
      maxWidth={600}
    >
      <Container
        maxWidth="md"
        sx={{
          mt: 2,
          overflowY: "auto",
          maxHeight: "calc(100vh - 150px)",
        }}
      >
        <Grid container justifyContent="flex-end">
          <Grid item xs={12}>
            <Stack
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
              sx={{
                mb: 2,
              }}
            >
              <Stack direction="row">
                <Typography variant="h6">Horário de {isEntrada ? "Entrada" : "Saída"}:</Typography>
              </Stack>
            </Stack>
            {selectedDayInterval.map((interval, index) => (
              <Stack
                key={index}
                direction="row"
                flexWrap="wrap"
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: 1,
                  p: 2,
                  mb: 2,
                }}
              >
                <Stack
                  key={`inner-${index}`}
                  direction="row"
                  spacing={2}
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textWrap: "nowrap",
                    }}
                  >
                    Período {index + 1}:
                  </Typography>
                  <TextField
                    label="De:"
                    value={getDisplayValue(interval, "startTime")}
                    onChange={(event) => handleInputChange(event, interval.id)}
                    onBlur={(event) => handleInputBlur(event, interval.id, selectedDay)}
                    name="startTime"
                    variant="standard"
                    inputProps={{ maxLength: 5 }}
                  />
                  <HorizontalRuleIcon />
                  <TextField
                    label="Até:"
                    value={getDisplayValue(interval, "endTime")}
                    onChange={(event) => handleInputChange(event, interval.id)}
                    onBlur={(event) => handleInputBlur(event, interval.id, selectedDay)}
                    name="endTime"
                    variant="standard"
                    inputProps={{ maxLength: 5 }}
                  />
                  <IconButton
                    onClick={(e) => removerPeriodo(e, interval.id, selectedDay)}
                    sx={{
                      width: "unset",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Grid>
          {selectedDayInterval.length < 4 && (
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              sx={{
                mb: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => adicionarPeriodo(event, selectedDay)}
                sx={{
                  mt: 2,
                }}
              >
                Adicionar Período
              </Button>
            </Grid>
          )}
          {selectedDayInterval && selectedDayInterval.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: "row",
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="column"
                justifyContent="space-between"
                sx={{
                  mb: 2,
                }}
              >
                <Typography variant="h6">Ajustar Globalmente</Typography>
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  mb: 2,
                }}
              >
                {diasSemana.map((dia, index) => {
                  const isDisabled = selectedDay === dia.value
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      sx={{
                        alignItems: "center",
                        mr: 1,
                        minWidth: 115,
                      }}
                    >
                      <Checkbox
                        checked={checkedDays[dia.value]}
                        onClick={(event) => {
                          setCheckedDays({
                            ...checkedDays,
                            [dia.value]: !checkedDays[dia.value],
                          });
                          handleMultipleChanges(event, !checkedDays[dia.value], false, selectedDay)
                        }}
                        name={dia.value}
                        disabled={isDisabled}
                        sx={{
                          margin: "0 !important",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          textWrap: "nowrap",
                        }}
                      >
                        {dia.name}
                      </Typography>
                    </Stack>
                  )
                })}
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center"
                  }}
                >
                  <Checkbox
                    checked={isKeySelected(selectedDayInterval, '', true)}
                    onClick={(event) => handleMultipleChanges(event, !isKeySelected(selectedDayInterval, '', true), true, selectedDay)}
                    name={'isAll'}
                    sx={{
                      margin: '0 !important'
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      textWrap: 'nowrap'
                    }}
                  >
                    Todos
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              flexDirection: "row"
            }}
          >
            <Stack
              alignItems="flex-end"
              direction="column"
              justifyContent="space-between"
              sx={{
                mb: 2
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLocalSave()}
                sx={{
                  mt: 2
                }}
                disabled={!hasChanges}
              >
                Salvar
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
}

export default HorarioInputs;