import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetAll() {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      listPainelConciliacaoMatriculas
    },
    errors
  } = await client.query({
    query: gql`
    query ListPainelConciliacaoMatriculas {
      listPainelConciliacaoMatriculas {
        value {
          id
          matriculaId
          matricula
          aluno
          unidade
          ano
          ordemVenda
          financeiro
          ficha
          servico
          origem
        }
        messages {
          text
          typeMessage
        }
        statusCode
      }
    }
    `
  })

  dispatchGraphError(errors);

  return listPainelConciliacaoMatriculas;
}

export async function Validar(idMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      validarPainelConciliacaoMatriculas
    },
    errors
  } = await client.query({
    query: gql`
        query ValidarPainelConciliacaoMatriculas($idMatricula: ID!) {
            validarPainelConciliacaoMatriculas(idMatricula: $idMatricula) {
                value
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      idMatricula
    }
  })

  dispatchGraphError(errors);

  return validarPainelConciliacaoMatriculas;
}

export async function Verificar(codigoMatricula) {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      verificarPainelConciliacaoMatriculas
    },
    errors
  } = await client.query({
    query: gql`
        query VerificarPainelConciliacaoMatriculas($codigoMatricula: String) {
            verificarPainelConciliacaoMatriculas(codigoMatricula: $codigoMatricula) {
                value
                messages {
                    text
                    typeMessage
                }
                statusCode
            }
        }
    `,
    variables: {
      codigoMatricula
    }
  })

  dispatchGraphError(errors);

  return verificarPainelConciliacaoMatriculas;
}