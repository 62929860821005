import { gql } from "@apollo/client";
import { dispatchGraphError } from "../utils/dispatchGraphError";

export async function GetAllStudentWithIncomeTax(cpfCnpj) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            getStudentsWithIncomeTax
        },
        errors
    } = await client.query({
        query: gql`
        query GetStudentsWithIncomeTax($cpfCnpj: String) {
            getStudentsWithIncomeTax(cpfCnpj: $cpfCnpj) {
                response {
                    clientCode
                    studentName
                    studentCode
                    matriculaERPId
                    year
                }
                message
            }
        }
        `,
        variables: {
            cpfCnpj
        }
    })

    dispatchGraphError(errors);

    return getStudentsWithIncomeTax;
}



export async function GenerateIncomeTaxDocument(incomeRequest) {
    /** @type ApolloClient<NormalizedCacheObject> */
    // @ts-ignore
    const client = window.apolloClient;
    const {
        data: {
            generateIncomeTaxDocument
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation GenerateIncomeTaxDocument($incomeRequest: IncomeRequest) {
                generateIncomeTaxDocument(incomeRequest: $incomeRequest) {
                    response
                    message
                }
            }
        `,
        variables: {
            incomeRequest
        }
    })

    dispatchGraphError(errors);

    return generateIncomeTaxDocument;
}